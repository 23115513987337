import { useMemo } from "react";
import { typedKeys } from "./utils";
export type Validator<TValue> = (value: TValue) => string | undefined | null;
export const validateObject = <Source extends {}>(
  validationObject: { [P in keyof Source]?: Validator<P>[] },
  value: Source
) =>
  typedKeys(validationObject).reduce((acc, key) => {
    const validators = validationObject[key];
    const validationError = (() => {
      for (const validator of validators ?? []) {
        //@ts-ignore: I got this TS
        const validationError = validator(value[key]);
        if (validationError) {
          return validationError;
        }
      }
    })();
    if (validationError) {
      acc[key] = validationError;
    }
    return acc;
  }, {} as { [P in keyof Source]?: string | undefined });

export default function useValidation<Source extends {}>(
  validationObject: { [P in keyof Source]?: Validator<P>[] },
  value: Source
) {
  return useMemo(() => validateObject(validationObject, value), [
    validationObject,
    value,
  ]);
}

export const email = (errorMsg: string): Validator<string> => (
  value: string
) => {
  const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return regex.test(value) ? null : errorMsg;
};

export const required = (errorMsg: string): Validator<string> => (val) =>
  (typeof val === "string" ? val?.length ?? 0 > 0 : val) ? null : errorMsg;

export const minLength = (
  length: number,
  errorMsg: (length: number) => string
): Validator<string> => (val) =>
  val === null || val.length >= length ? null : errorMsg(length);

export const maxLength = (
  length: number,
  errorMsg: (length: number) => string
): Validator<string> => (val) =>
  val === null || val.length < length ? null : errorMsg(length);
