export const typedKeys = Object.keys as <T>(object: T) => (keyof T)[];

export const omit = <T, Key extends keyof T>(source: T, ...keysToOmit: Key[]) =>
  Object.entries(source).reduce((acc, [key, value]) => {
    //@ts-ignore: yeah this is shenanigans
    keysToOmit.indexOf(key) === -1 && (acc[key] = value);
    return acc;
  }, {} as Omit<T, Key>);

export const copyObjectWithPropValue = <Source, Value>(
  source: Source,
  value: Value
) =>
  typedKeys(source).reduce((acc, key) => {
    acc[key] = value;
    return acc;
  }, {} as { [key in keyof Source]: Value });
