import { css } from "@emotion/core";
import React, { useMemo, useState, useRef, useEffect } from "react";
import { LinkButton } from "../components/Atoms/Button";
import { Heading1 } from "../components/Atoms/Text";
import ContentPageLayout from "../components/Organisms/ContentPageLayout";
import NominateForm from "../components/Organisms/NominateForm";
import Seo from "../components/Seo";
import { verticalMargin, horizontalContentWidth } from "../margins";
import { bpLarge, colorGrayFill } from "../stylingConstants";
import { graphql } from "gatsby";
import { NominateTemplateQuery } from "../../graphql-schema-types";
import ContentPageRichText from "../components/Organisms/ContentPageRichText";
import { useGlobalSiteData, useEffectWithDeps } from "../hooks";

type Props = {
  data: NominateTemplateQuery;
};

export default function Nominate({
  data: { contentfulSharedContent: data },
}: Props) {
  const { menuItems } = useGlobalSiteData();
  const aboutLink = useMemo(
    () =>
      menuItems.find(
        (item) => item.text?.toLowerCase().indexOf("priset") !== -1
      ),
    [menuItems]
  );
  const [submissionComplete, setSubmissionComplete] = useState(false);
  const main = useRef<HTMLDivElement>(null);
  useEffectWithDeps(
    (deps) => {
      if (deps?.[0] != null && main.current != null) {
        main.current.scrollIntoView();
      }
    },
    [submissionComplete]
  );
  return (
    <>
      <Seo title="Nominera" description="Nominera bidrag" />
      <section
        css={css`
          margin-right: 0;
          margin-left: 0;
          min-height: auto;
        `}
      >
        <ContentPageLayout
          ref={main}
          css={css`
          ${horizontalContentWidth}
          ${verticalMargin(48)}
          @media ${bpLarge}{
            ${verticalMargin(68)}
          }
        `}
        >
          <Heading1
            css={css`
              margin-bottom: 36px;
              @media ${bpLarge} {
                margin-bottom: 48px;
              }
            `}
          >
            Nominera
          </Heading1>
          {submissionComplete ? (
            <>
              {
                <ContentPageRichText
                  json={data?.nominationSubmissionConfirmation?.json}
                />
              }
              <LinkButton to="/">Tillbaka till start</LinkButton>
            </>
          ) : (
            <>
              {data?.nominationsIntroduction?.json && (
                <ContentPageRichText json={data.nominationsIntroduction.json} />
              )}
              {aboutLink && (
                <LinkButton
                  to={aboutLink.url}
                  css={css`
                    margin-top: 24px;
                    @media ${bpLarge} {
                      margin-top: 32px;
                    }
                  `}
                >
                  Läs mer om priset
                </LinkButton>
              )}
            </>
          )}
        </ContentPageLayout>
      </section>
      <section
        css={css`
          background-color: ${colorGrayFill};
        `}
        key="form"
      >
        {data?.nominationsOpen && !submissionComplete && (
          <ContentPageLayout css={horizontalContentWidth}>
            <NominateForm setComplete={() => setSubmissionComplete(true)} />
          </ContentPageLayout>
        )}
      </section>
    </>
  );
}

export const query = graphql`
  query NominateTemplate {
    contentfulSharedContent {
      nominationsOpen
      nominationsIntroduction {
        json
      }
      nominationSubmissionConfirmation {
        json
      }
      nominationSubmissionConfirmation {
        json
      }
    }
  }
`;
